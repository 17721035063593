<template>
  <div ref="wrapper">
    <div class="d-flex flex-nowrap mt-2">
      <div style="flex-grow: 1; max-width: 100%">
        <button
          v-b-tooltip="$t(field.helpSnippet)"
          class="btn btn-primary"
          @click="fireAction(field)"
        >
          {{
            cancelAction
              ? field.label + " " + $t("general.cancel")
              : field.label
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    }
  },
  data() {
    return {
      cancelAction: false
    };
  },
  mounted() {
    bus.$on("fireActionFinished", this.onFireActionFinished);
  },

  destroyed() {
    bus.$off("fireActionFinished", this.onFireActionFinished);
  },
  methods: {
    onFireActionFinished() {
      this.cancelAction = false;
    },
    fireAction(field) {
      this.cancelAction = !this.cancelAction;
      bus.$emit("fireAction", field, !this.cancelAction);
    }
  }
};
</script>
